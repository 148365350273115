import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Container } from "react-bootstrap";
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Privacy from "./Components/Privacy";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./App.css";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Switch>
          <Container fluid>
            <Header />
            <Route exact path={["/home", "/"]} component={Home} />
            <Route exact path="/about" component={About} />
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_SITE_KEY}
            >
              <Route exact path="/contact" component={Contact} />
            </GoogleReCaptchaProvider>
            <Route exact path="/privacy" component={Privacy} />
            <Redirect to="/" />
            <Footer />
          </Container>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
