import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Fade, Zoom } from "react-reveal";
import AboutHeader from "./AboutHeader";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <Row className="App-background">
        <Col>
          <AboutHeader />
        </Col>
      </Row>
      <Row className="App-section-light">
        <Col className="App-section-col App-about-services-section-image">
          <Zoom>
            <h1
              className="App-about-services-section-text"
              style={{ color: "#e6e4e1", fontWeight: "bolder" }}
            >
              Our Services
            </h1>
          </Zoom>
        </Col>
        <Col className="App-section-col">
          <Fade>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>Design</p>
            <p className="App-section-col-text">
              Here at CAST, we are passionate about design. It’s the key to any
              successful product, whether it be digital content, branding
              materials or the user experience. Our approach to design fuels the
              creation of cohesive and sohpisticated products. Content that
              resonates deeply with users ultimately drives engagement and
              growth.
            </p>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>Develop</p>
            <p className="App-section-col-text">
              We develop bespoke digital products and we specialize in custom
              built responsive websites to get your business online. All of our
              products are built to allow your users to engage with your
              business at their convenience. We develop for all digital
              platforms to ensure we deliver a functional, tangible service for
              your users that will ultimately enhance your business.
            </p>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>Deliver</p>
            <p className="App-section-col-text">
              Choose us to be your technology partner for domains, websites,
              email, secure hosting and more. We can get your product online and
              into the hands of your audience with our fast, reliable and
              affordable web hosting services. We are proactive tech experts and
              will work with you to create, deploy, maintain and enhance your
              digital presence smoothly at a pace and level perfectly suited to
              you and your business.
            </p>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>More...</p>
            <p className="App-section-col-text">
              We offer many more services from Graphic Design, Search Engine
              Optimisation to eCommerce and Content Creation.
              <br />
              <br />
              Get in touch for a free consultation and find out what we can do
              for your business.
            </p>
            <Button
              style={{ marginTop: "20px", padding: "20px" }}
              className="btn-contact"
              as={Link}
              to="/contact"
            >
              Contact Us
            </Button>
          </Fade>
        </Col>
      </Row>
      <Row className="App-section-dark">
        <Col className="App-section-col">
          <Fade>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>Empathise</p>
            <p className="App-section-col-text">
              We believe that Empathy is the foundation of human-centered
              design. At CAST we are unique in the understanding that the
              problems we try to solve are rarely our own, they're those of your
              customers. Unlocking empathy for your users is the key to success
              for all your digital products.
            </p>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>
              Collaborate
            </p>
            <p className="App-section-col-text">
              When you engage with CAST you will immediately begin to see the
              benefits of collaborating with us as your digital product
              partners. Our collaborative, methodical process means we can
              create solutions that solve your core objectives. We always
              onboard your feedback and this is key to how we deliver
              functional, well thought-out digital products.
            </p>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>Progress</p>
            <p className="App-section-col-text">
              Digital design and development excite us at CAST. We always
              innovate using the latest standards, approaches and technologies
              appropriate to you. We believe in our products and are confident
              that when they deliver tangible results, we will become your go-to
              source for optimising all of your digital products to deliver long
              lasting value for your users.
            </p>
            <Button
              style={{ marginTop: "20px", padding: "20px" }}
              className="btn-contact"
              as={Link}
              to="/contact"
            >
              Contact Us
            </Button>
          </Fade>
        </Col>

        <Col className="App-section-col App-about-values-section-image">
          <Zoom>
            <h1
              className="App-about-section-text"
              style={{ fontWeight: "bolder" }}
            >
              Our Values
            </h1>
          </Zoom>
        </Col>
      </Row>
    </>
  );
}

export default About;
