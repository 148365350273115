import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import {
  List,
  Envelope,
  Facebook,
  Instagram,
} from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import logo from "../Images/cast-logos/cast-square-green-white.png";
import canvasLogo from "../Images/cast-logos/cast-square-green-white-full.png";

function Header() {
  const [active, setActive] = useState("/home");
  const [offset, setOffset] = useState(0);
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.scrollY);
    };
  }, []);

  useEffect(() => {
    setActive(pathname);
    setOffset(window.scrollY);
  }, [pathname]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        className={offset >= 1 || pathname === "/privacy" ? "App-navbar" : "App-navbar-transparent"}
      >
        <Container>
          <Navbar.Brand as={Link} to="/home" className="App-navbar-brand">
            <img
              style={{ height: "75px", width: "auto" }}
              src={logo}
              alt="Cast logo"
            />
          </Navbar.Brand>
          <Button className="btn-menu" onClick={handleShow}>
            <List
              style={{
                color: "whitesmoke",
                borderColor: "whitesmoke !important",
                height: "50px",
                width: "auto",
              }}
              onClick={handleShow}
            />{" "}
          </Button>
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            style={{ backgroundColor: "#535353", color: "whitesmoke" }}
          >
            <Offcanvas.Header closeButton closeVariant="white">
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav activeKey={active}>
                <Row>
                  <Col style={{ minWidth: "20%" }} />
                  <Col>
                    <Nav.Link
                      eventKey="/home"
                      as={Link}
                      to="/home"
                      onClick={handleClose}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1.5rem",
                      }}
                    >
                      Home
                    </Nav.Link>
                  </Col>
                  <Col style={{ minWidth: "20%" }} />
                </Row>
                <Row>
                  <Col style={{ minWidth: "20%" }} />
                  <Col>
                    <Nav.Link
                      eventKey="/about"
                      as={Link}
                      to="/about"
                      onClick={handleClose}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10vh",
                        fontSize: "1.5rem",
                      }}
                    >
                      About
                    </Nav.Link>
                  </Col>
                  <Col style={{ minWidth: "20%" }} />
                </Row>
                <Row>
                  <Col style={{ minWidth: "20%" }} />
                  <Col>
                    <Nav.Link
                      eventKey="/contact"
                      as={Link}
                      to="/contact"
                      onClick={handleClose}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10vh",
                        fontSize: "1.5rem",
                      }}
                    >
                      Contact
                    </Nav.Link>
                  </Col>
                  <Col style={{ minWidth: "20%" }} />
                </Row>
              </Nav>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "5vh",
                  paddingBottom: "5vh",
                }}
              >
                <img
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "40%",
                  }}
                  src={canvasLogo}
                  alt="Cast logo"
                />
              </Row>
              <Row
                className="App-footer-socials"
                style={{
                  display: "flex",
                }}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/castwebdesign"
                >
                  <Facebook style={{ color: "whitesmoke" }} size={35} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/castwebdesign/"
                >
                  <Instagram style={{ color: "whitesmoke" }} size={35} />
                </a>
                <a href="mailto:info@castwebdesign.co.uk?subject=Enquiry from castwebdesign.co.uk">
                  <Envelope style={{ color: "whitesmoke" }} size={35} />
                </a>
              </Row>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
