import React from "react";
import logo from "../Images/cast-logos/cast-about.png";
import Zoom from "react-reveal/Zoom";

function AboutHeader(props) {
  const { title } = props;
  return (
    <>
      <Zoom>
        <div className="Page-header">
          {title ? (
            <p
              style={{
                fontSize: "3rem",
                fontWeight: "bolder",
                maxWidth: "75%",
              }}
            >
              {title}
            </p>
          ) : (
            <img style={{ maxWidth: "75%" }} src={logo} alt="Cast logo" />
          )}
        </div>
      </Zoom>
    </>
  );
}

export default AboutHeader;
