import React, { useState, useEffect, useCallback } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Spinner,
  FloatingLabel,
} from "react-bootstrap";
import axios from "axios";
import validator from "validator";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Contact() {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);

  const validateName = (e) => {
    if (validator.isEmpty(e)) {
      setNameError(true);
      return false;
    } else {
      setNameError(false);
      return true;
    }
  };

  const validateEmail = (e) => {
    if (validator.isEmail(e)) {
      setEmailError(false);
      return true;
    } else {
      setEmailError(true);
      return false;
    }
  };

  const validatePhone = (e) => {
    if (validator.isNumeric(e)) {
      setPhoneError(false);
      return true;
    } else {
      setPhoneError(true);
      return false;
    }
  };

  const validateMessage = (e) => {
    if (validator.isEmpty(e)) {
      setMessageError(true);
      return false;
    } else {
      setMessageError(false);
      return true;
    }
  };

  const resetFormFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    await executeRecaptcha();
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const validateForm = () => {
    if (
      !validateName(name) ||
      !validateEmail(email) ||
      !validatePhone(phone) ||
      !validateMessage(message)
    ) {
      console.log("failed validation");
      validateName(name);
      validateEmail(email);
      validatePhone(phone);
      validateMessage(message);
    } else {
      console.log("send the email");
      try {
        setSpinner(true);
        sendMessage().then((response) => {
          if (response.data === "Success") {
            setShowSuccessAlert(true);
            resetFormFields();
            setSpinner(false);
          } else {
            setShowFailureAlert(true);
            setSpinner(false);
          }
        });
      } catch (e) {
        setSpinner(false);
        setShowFailureAlert(true);
      }
    }
  };

  async function sendMessage() {
    var object = {};
    object["to"] = "info@castwebdesign.co.uk";
    object["subject"] = "Website Contact Form:";
    object["name"] = name;
    object["email"] = email;
    object["phone"] = phone;
    object["message"] = message;

    const response = axios.post(
      "https://us-central1-cast-e2a50.cloudfunctions.net/sendMail?",
      object
    );

    return response;
  }

  return (
    <>
      <Row
        className="App-section-contact App-background"
        style={{ minHeight: "100vh" }}
      >
        <Col className="App-section-col-contact">
          <Form autoComplete="off" style={{ paddingTop: "80px" }}>
            <p
              style={{
                fontSize: "2.5rem",
                color: "whitesmoke",
              }}
            >
              Get in touch...
            </p>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    required
                    className={nameError ? "is-invalid" : ""}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {nameError ? (
                    <div className={"inline-errormsg"}>
                      Please enter your name
                    </div>
                  ) : (
                    <div />
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Email" className="mb-3">
                  <Form.Control
                    className={emailError ? "is-invalid" : ""}
                    type="email"
                    placeholder="Enter Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError ? (
                    <div className={"inline-errormsg"}>
                      Please enter your email address
                    </div>
                  ) : (
                    <div />
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Phone" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone"
                    required
                    className={phoneError ? "is-invalid" : ""}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {phoneError ? (
                    <div className={"inline-errormsg"}>
                      Please enter your phone number
                    </div>
                  ) : (
                    <div />
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Message" className="mb-3">
                  <Form.Control
                    className={messageError ? "is-invalid" : ""}
                    as="textarea"
                    required
                    placeholder="Enter Message"
                    style={{ height: "150px" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  {messageError ? (
                    <div className={"inline-errormsg"}>
                      Please enter a message
                    </div>
                  ) : (
                    <div />
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
            {showSuccessAlert && (
              <Alert
                style={{ marginTop: "16px" }}
                variant="success"
                onClose={() => setShowSuccessAlert(false)}
                dismissible
              >
                <Alert.Heading>Thank you for getting in touch!</Alert.Heading>
                <p>We will respond to your message as soon as we can</p>
              </Alert>
            )}
            {showFailureAlert && (
              <Alert
                style={{ marginTop: "16px" }}
                variant="danger"
                onClose={() => setShowFailureAlert(false)}
                dismissible
              >
                <Alert.Heading>
                  Uh-oh! That message failed to send.
                </Alert.Heading>
                <p>
                  Try sending us a message using this link -{" "}
                  <a
                    style={{ color: "#272725" }}
                    href="mailto:info@castwebdesign.co.uk?subject=Enquiry from castwebdesign.co.uk"
                  >
                    info@castwebdesign.co.uk
                  </a>
                </p>
              </Alert>
            )}
            <Button
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                minWidth: "100px",
              }}
              variant="success"
              onClick={() => {
                validateForm();
              }}
            >
              {!spinner && "Submit"}
              {spinner && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Contact;
