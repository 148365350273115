import React from "react";
import { Link } from "react-router-dom";
import { Button, Carousel, Col, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import PageHeader from "./PageHeader";
import WebDesignLogo from "../Images/carousel-images/design-image.jpg";
import WebDevelopLogo from "../Images/carousel-images/develop-image.jpg";
import WebDeliverLogo from "../Images/carousel-images/deliver-image.jpg";
import Zoom from "react-reveal/Zoom";

function Home() {
  return (
    <>
      <Row className="App-background">
        <Col>
          <PageHeader />
        </Col>
      </Row>
      <Row
        className="App-section-light"
        style={{
          alignItems: "center",
        }}
      >
        <Col className="App-section-col">
          <Fade>
            <p style={{ fontSize: "2rem", fontWeight: "bolder" }}>
              What we can do for you
            </p>
            <p className="App-section-col-text">
              At Cast, we're passionate about our customers. Your success is our
              success and we strive to design and develop first class products
              and experiences.
            </p>
            <p className="App-section-col-text">
              Our aim is to bring your ideas to life for your customers. Every
              business needs an intuitive digital platform to deliver their
              services, and we can build yours.
            </p>
            <p className="App-section-col-text">
              We believe your website is an investment in the success of your
              business and we specialize in designing, developing and hosting
              custom websites.
            </p>
            <p className="App-section-col-text">
              Why not reach out for a free consultation to see what we can do
              for you and your business?
            </p>
            <Button
              className="btn-contact"
              as={Link}
              to="/Contact"
              style={{ marginTop: "20px", padding: "20px" }}
            >
              Contact Us
            </Button>
          </Fade>
        </Col>
        <Col className="App-section-col">
          <Carousel fade>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src={WebDesignLogo}
                alt="Design slide"
              />
              <Carousel.Caption>
                <Zoom>
                  <p style={{ fontSize: "3rem", fontWeight: "bolder" }}>
                    Design
                  </p>
                  <Button
                    style={{ marginTop: "20px", padding: "20px" }}
                    className="btn-contact"
                    as={Link}
                    to="/About"
                  >
                    Find out more
                  </Button>
                </Zoom>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img className="w-100" src={WebDevelopLogo} alt="Develop slide" />
              <Carousel.Caption>
                <Zoom>
                  <p style={{ fontSize: "3rem", fontWeight: "bolder" }}>
                    Develop
                  </p>
                  <Button
                    style={{ marginTop: "20px", padding: "20px" }}
                    className="btn-contact"
                    as={Link}
                    to="/About"
                  >
                    Find out more
                  </Button>
                </Zoom>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                className="d-block w-100"
                src={WebDeliverLogo}
                alt="Deliver slide"
              />
              <Carousel.Caption>
                <Zoom>
                  <p style={{ fontSize: "3rem", fontWeight: "bolder" }}>
                    Deliver
                  </p>
                  <Button
                    style={{ marginTop: "20px", padding: "20px" }}
                    className="btn-contact"
                    as={Link}
                    to="/About"
                  >
                    Find out more
                  </Button>
                </Zoom>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </>
  );
}

export default Home;
