import React from "react";
import { Col, Row } from "react-bootstrap";
import { Envelope, Facebook, Instagram } from "react-bootstrap-icons";
import logo from "../Images/cast-logos/cast-square-green-white-full.png";
import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <Row className="App-section-footer">
      <Col className="Footer-section-col">
        <img
          style={{ height: "250px", width: "auto" }}
          src={logo}
          alt="Cast Logo"
        />
        <Row className="App-footer-socials">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/castwebdesign/"
          >
            <Facebook style={{ color: "#535353" }} size={35} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/castwebdesign/"
          >
            <Instagram style={{ color: "#535353" }} size={35} />
          </a>
          <a href="mailto:info@castwebdesign.co.uk?subject=Enquiry from castwebdesign.co.uk">
            <Envelope style={{ color: "#535353" }} size={35} />
          </a>
        </Row>
        <Row className="Footer-section-col-text">
          <a
            style={{ color: "#272725" }}
            href="mailto:info@castwebdesign.co.uk?subject=Enquiry from castwebdesign.co.uk"
          >
            info@CastWebDesign.co.uk
          </a>
        </Row>
        <Row className="Footer-section-col-text">
          <Link style={{ color: "#272725" }} to="/privacy">
            Privacy Policy
          </Link>
        </Row>
        <Row className="Footer-section-col-text">
          <p>© CastWebDesign.co.uk</p>
        </Row>
      </Col>
    </Row>
  </>
);

export default Footer;
